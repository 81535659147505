@tailwind base;
@tailwind components;
@tailwind utilities;

.loading-indicator:before {
    content: "";
    background: #000000cc;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: url("./assets/gifs/loading.gif");
    position: fixed;
    width: 100%;
    top: 40%;
    left: 0;
    z-index: 1001;
    text-align: center;
  }

  